//四人房

//假日
export const FourBedroomPeakPrice = "2200"
//平日
export const FourBedroomOffpeakPrice = "1800"

//雙人房

//假日
export const TwoBedroomPeakPrice = "1500"
//平日
export const TwoBedroomOffpeakPrice = "1200"
